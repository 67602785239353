import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import imgBanner from "../assets/image/inner/about-banner.png";
import Fact from "../sections/landing1/Fact";
import styled from "styled-components";

const CheckStyled = styled.label`
  h2,h3,h4,h5 {
    color: var(--color-headings);
    // font-weight: 500;
  }

  p {
    color: var(--color-headings);
    margin-bottom: 50px;    
  }
`;


const AboutPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="px-md-12 text-center mb-11 mb-lg-14">
                  <h2 className="title gr-text-2 mb-9 mb-lg-12 heading-color">TRAINING &amp; SUPPORT PROGRAMME</h2>
                  <p>AIN ensures expertise in emerging technologies, fostering continuous learning and innovation for superior service delivery.</p>
                </div>
              </Col>

            </Row>
          </Container>
        </div>
        <div className="main-block pb-6 pb-lg-17 bg-default-6">
          <Container>
            <Row className="justify-content-center heading-color">
              <Col xl="8" lg="9">
                <CheckStyled>
                  <h3 id="training-programme">Training Programme</h3>
                  <p>AIN can help you acquire new skills or sharpen your existing expertise. We can help you achieve your education goals for IBM &amp; Oracle software solutions. A broad spectrum of training courses can be delivered by our in house staff or by our training partners.</p>
                  <h3 id="knowledge-transfer">Knowledge Transfer</h3>
                  <p>We also deliver 1 on 1 Trainings &amp; Knowledge Transfer for critical assignments to all our Individual &amp; Corporate Clientele. A broad spectrum of training courses can be delivered by our in house staff or by our training partners.</p>
                  <h3 id="training-programme-reselling">Training Programme Reselling</h3>
                  <p>We sell and resell a lot of popular courses, so we can - Help you find and register for courses at a discount Develop and deliver a customized courses based on audience Delivery mechanism can be Virtual, onsite or near your company site Sign Up Now Process AIN has well-laid out all the process relevant to Staffing, Services, Sales and Training.</p>
                  <h3 id="enablement-process">Enablement Process</h3>
                  <p>AIN will work with you to get all your current vendors enrolled. You will want to continue enrolling any new vendors. You will also want to follow up with any current vendors who do not get enrolled during the initial period.</p>
                  <h3 id="project-management-delivery-process-single-html-">Project Management &amp; Delivery Process </h3>
                  <p>Organizations continue to be challenged with the consistent application of defined project management processes, resource planning, forecasting, and other business demands.</p>
                  <h3 id="get-in-touch-with-us-">Get in touch with us.</h3>
                  <p>Whether its a simple question or about the next happening huge thing at your company, Please leave in a Message. We will be more than happy to get in touch with you.</p>
                  <p>We are constantly creating, acquiring and retaining key talent to deliver superior value to our customers. Create opportunities every moment by building meaningful relationships and nurture them by remaining a trusted partner. We firmly believe and stick to our culture and values that mirror the way we interact and work, built on the foundation of imagination, learning and freedom to operate.</p>
                </CheckStyled>
              </Col>
            </Row>
          </Container>
        </div>
        <Fact />
            
      </PageWrapper>
    </>
  );
};
export default AboutPage;
